import {createWallet, loginRequest, getMeAuthRequest, depositRequest, withdrawRequest, getAdminInfoRequest, switchRequest} from "@/api/auth/request";
import { handleLogout } from "@/utils/function";


const state = {
  activeWithdrawMethod: 0,
  errorLogin: false,
  auth: false,
  loading: false,
  infoMe: {},
  language: localStorage.getItem("lang") || "ko",
};

const getters = {
  auth(state) {
    return state.auth;
  },
};

const mutations = {
  handleChangeWithdrawMethod(state, payload) {
    state.activeWithdrawMethod = payload;
  },

  handleChangeErrorLogin(state, payload) {
    state.errorLogin = payload;
  },

  handleChangeLanguage(state, payload) {
    state.language = payload;
  },
  handleAuth(state, payload) {
    state.auth = payload;
  },
  handleUpdateLoading(state, payload) {
    state.loading = payload;
  },
  handleUpdateInfoMe(state, payload) {
    state.infoMe = payload;
  },
};

const actions = {
  startIntervalAction({ commit }, payload) {
    commit("handleSetInterval", payload);
  },
  clearIntervalAction({ commit }) {
    commit("handleClearInterval");
  },
  startIntervalPingAction({ commit }, payload) {
    commit("handleSetIntervalPing", payload);
  },
  clearIntervalPingAction({ commit }) {
    commit("handleClearIntervalPing");
  },
  async userLogin({ commit }, data) {
    const res = await loginRequest(data);
    return res;
  },

  async getAdminInfoAction({ commit }) {
    const res = await getAdminInfoRequest();
    return res;
  },

  async getMeAuthAction({ commit }) {
    const res = await getMeAuthRequest();

    if (res?.data?.status == 0) {
      commit("handleUpdateInfoMe", res?.data.data);
      return res?.data;
    } else {
      handleLogout();
    }
    return res;
  },

  async userDeposit({ commit }, data) {
    const res = await depositRequest(data);
    return res;
  },

  async userWithdraw({ commit }, data) {
    const res = await withdrawRequest(data);
    return res;
  },

  async userSwitch({ commit }, data) {
    const res = await switchRequest(data);
    return res;
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
